/*
Types:
- M: Manager
- A: Admin
- P: Prolancer
 */
const roles = [
	{
		type: ['M'],
		value: 'SUCCESS_MANAGER',
		name: 'Gerente de Sucesso'
	},
	{
		type: ['M'],
		value: 'RECRUITER',
		name: 'Recrutador(a)'
	},
	{
		type: ['A'],
		value: 'ADMINISTRATOR',
		name: 'Admin'
	},
	{
		type: ['A'],
		value: 'FINANCIAL',
		name: 'Financeiro(a)'
	},
	{
		type: ['A'],
		value: 'REVIEWER',
		name: 'Avaliador(a)'
	},
	{
		type: ['P', 'J'],
		value: 'PRODUCT_MANAGER',
		name: 'Gerente de produto'
	},
	{
		type: ['P', 'J'],
		value: 'PRODUCT_OWNER',
		name: 'Product Owner'
	},
	{
		type: ['P', 'J'],
		value: 'PRODUCT_DESIGNER',
		name: 'Designer de produto'
	},
	{
		type: ['P', 'J'],
		value: 'FRONTEND_DEVELOPER',
		name: 'Dev. Front-end'
	},
	{
		type: ['P', 'J'],
		value: 'BACKEND_DEVELOPER',
		name: 'Dev. Back-end'
	},
	{
		type: ['P', 'J'],
		value: 'FULLSTACK_DEVELOPER',
		name: 'Dev. Full-stack'
	},
	{
		type: ['P', 'J'],
		value: 'MOBILE_DEVELOPER',
		name: 'Dev. Mobile'
	},
	{
		type: ['P', 'J'],
		value: 'DATA_ENGINEER',
		name: 'Eng. de dados',
	},
	{
		type: ['P', 'J'],
		value: 'DATA_ANALYST',
		name: 'Analista de dados',
	},
	{
		type: ['P', 'J'],
		value: 'SOFTWARE_ARCHITECT',
		name: 'Arq. de software',
	},
	{
		type: ['P', 'J'],
		value: 'QUALITY_ASSURANCE',
		name: 'Quality Assurance'
	},
	{
		type: ['P', 'J'],
		value: 'DEVOPS',
		name: 'DevOps'
	},
	{
		type: ['J'],
		value: 'TECH_LEAD',
		name: 'Tech Lead',
	},
	{
		type: ['J'],
		value: 'DATAOPS',
		name: 'DataOps',
	}
];

// Recebe um tipo de role (string) ou uma lista de tipos (array de string)
// Ex.: 'P' ou 'A'
// Retorna uma lista de roles (array de roles)
// Ex.: [
//   { type: 'P', value: 'PRODUCT', name: 'Produto' }
//   { type: 'A', value: 'Admin', name: 'Admin' },
// ]
export const getRolesByTypes = types => {
	if (!types) return roles;

	if (Array.isArray(types)) {
		let result = [];
		for (let roleType of types) {
			result.push(
				roles.filter(
					role =>
						role.type.includes(roleType) &&
						!result.find(resultRole => role.value === resultRole.value)
				)
			);
			result = result.flat();
		}
		return result;
	} else {
		return roles.filter(role => role.type.includes(types));
	}
};

// Recebe um tipo de role (string) ou uma lista de tipos (array de string)
// Ex.: 'P' ou 'A'
// Retorna uma lista de roles (array de roles)
// Ex.: [
//   { type: 'P', value: 'PRODUCT', name: 'Produto' }
//   { type: 'A', value: 'Admin', name: 'Admin' },
// ]
export const getRolesByValues = values => {
	if (!values) return roles;

	if (Array.isArray(values)) {
		return values
			.filter(value => roles.filter(role => role.value === value).length > 0)
			.map(value => roles.filter(role => role.value === value))
			.flat();
	} else {
		return roles.filter(role => role.value === values);
	}
};

// Recebe uma role (string) ou uma lista de roles (array de string)
// Ex.: 'SUCCESS_MANAGER' ou ['PRODUCT_MANAGER', 'FULLSTACK_DEVELOPER']
// Retorna uma string com a descrição da(s) role(s) separadas por vírgula
// Ex.: 'Gerente de sucesso' ou 'Gerente de produto, Dev full-stack'
export const getRolesNames = values => {
	if (!values) return roles.map(role => role.name).join(', ');

	if (Array.isArray(values)) {
		return values
			.filter(value => roles.filter(role => role.value === value).length > 0)
			.map(value => roles.find(role => role.value === value).name)
			.join(', ');
	} else {
		return roles.find(role => role.value === values)
			? roles.find(role => role.value === values).name
			: '';
	}
};

// Recebe o nome de uma role (string)
// Ex.: 'Admin'
// Retorna uma string com o value da role
// Ex.: 'ADMINISTRATOR'
export const getRoleByName = name => {
	return roles.find(role => role.name === name) ? roles.find(role => role.name === name).value : '';
};

export default { getRolesByTypes, getRolesByValues, getRolesNames };
